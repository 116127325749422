{/* <div class="gallery-item-box row">
<div class="col-lg-4 col-md-6 gallery-item restaurant mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04557.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04557_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item restaurant mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04571.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04571_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item restaurant mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04580.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04580_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item planche mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04608.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04608_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item planche mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04625.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04625_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item planche mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04643.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04643_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item boisson mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04689.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04689_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item produit mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04718.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04718_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item produit mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04735.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04735_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item planche restaurant mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/restaurant-3.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/restaurant-3_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item planche restaurant mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04751.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04751_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item planche mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04772.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04772_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item planche mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04789.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04789_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item planche mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/DSC04811.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/DSC04811_mini.jpg" alt="">
    </div>
</div>
<div class="col-lg-4 col-md-6 gallery-item restaurant mb-30">
    <div class="single-item-gallery">
        <a href="images/gallery/grand/20190916_181924.jpg">
            <span class="plus"><i class="mdi mdi-plus"></i></span>
        </a>
        <img src="images/gallery/20190916_181924_mini.jpg" alt="">
    </div>
</div>

 */}




export const images = {
    gallery: [
        {
            src: "20190916_181924.jpg",
            thumbnail: "20190916_181924_mini.jpg",
            thumbnailWidth: 370,
            thumbnailHeight: 234,
            isSelected: false,
            caption: "After Rain (Jeshu John - designerspics.com)"
        }, {
            src: "20190916_181924.jpg",
            thumbnail: "20190916_181924_mini.jpg",
            thumbnailWidth: 370,
            thumbnailHeight: 234,
            isSelected: false,
            caption: "After Rain (Jeshu John - designerspics.com)"
        }, {
            src: "20190916_181924.jpg",
            thumbnail: "20190916_181924_mini.jpg",
            thumbnailWidth: 370,
            thumbnailHeight: 234,
            isSelected: false,
            caption: "After Rain (Jeshu John - designerspics.com)"
        },


        {
            src: "20190916_181924.jpg",
            thumbnail: "20190916_181924_mini.jpg",
            thumbnailWidth: 370,
            thumbnailHeight: 234,
            isSelected: false,
            caption: "After Rain (Jeshu John - designerspics.com)"
        },
        {
            src: "DSC_4685.jpg",
            thumbnail: "DSC_4685_mini.jpg",
            thumbnailWidth: 370,
            thumbnailHeight: 234,
            isSelected: false,
            caption: "After Rain (Jeshu John - designerspics.com)"
        },







        {
            src: "DSC_4687.jpg",
            thumbnail: "DSC_4687_mini.jpg",
            thumbnailWidth: 370,
            thumbnailHeight: 234,
            isSelected: false,
            caption: "After Rain (Jeshu John - designerspics.com)"
        },
        {
            src: "DSC_4689.jpg",
            thumbnail: "DSC_4689_mini.jpg",
            thumbnailWidth: 370,
            thumbnailHeight: 234,
            tags: [{ value: "Ocean", title: "Ocean" }, { value: "People", title: "People" }],
            caption: "Boats (Jeshu John - designerspics.com)"
        },
        {
            src: "DSC_4692.jpg",
            thumbnail: "DSC_4692_mini.jpg",
            thumbnailWidth: 370,
            thumbnailHeight: 234,
        }],
    homeSlider: [
        {
            src: "DSC_4692.jpg",
        },
        {
            src: "DSC_4692.jpg",
        },
    ]
};