import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import React from "react";
import "./alarme,controle,vs.css";
import vidsur from '../../../img/vid.jpg';
import surv from '../../../img/surv.jpg';
import { Link } from 'react-router-dom';

const Videosurveillance = () => {
    
    return (
        
    <div className="page">
    <h1>VidéoSurveillance</h1>
    <img src={vidsur} alt="Alarme" /><p>Nous proposons une sélection de caméras pour surveiller ce qui compte pour vous en temps réel et en relecture avec enregistrement. Nos solutions sont adaptées pour des caméras intérieures ou extérieures, à haute résolution, à détection de mouvement et meme thermiques.</p>    <p>Ces caméras peuvent être visionnées à distance via une application mobile pour une surveillance où que vous soyez.</p>
<img src={surv} alt="Alarme" />
    <button className="btn">
    <Link to={{ pathname: `/securite` }}>
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
    </button>
 </div>
  );
};

export default Videosurveillance;