import React, { Component, lazy } from "react";


const Widgets = lazy(() => import("../../../components/blog/Widgets"));
//const ContactUsRappelForm = lazy(() => import("../../components/ContactUsRappelForm"));

class DepannageView extends Component {
  constructor(props) {
    super();
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="card bg-dark text-white rounded-0 mb-3">
          <img
            src="../../assets/Images/Slider/depannage.jpg"
            className="card-img rounded-0"
            alt="..."
          />
          <div className="card-img-overlay text-center">
            <h1 className="card-title position-absolute bottom-0 mb-3">
              {/* Caméra intérieur ou extérieur, Lutte contre la démarque inconnue, dissuasion , surveillance interne , accès à distance, enregistrement , reconnaissance faciale, lecture de plaques … Grace aux dernières technologies les possibilités sont infinis pour la vidéosurveillance. */}
            </h1>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="display-6 border-bottom pb-3 mb-1">
               <strong>LES DEPANNAGES</strong>
              </div>
              <div className="sous-titre">Notre expertise en dépannage électrique et sécurité:</div>
            <p>Pour votre service de dépannage électrique comme de sécurité, AT Pro est là pour vous aider à gérer toutes vos urgences. Nous disposons d'une équipe d'électriciens professionnels qualifiés et expérimentés, disponibles toute l'année pour intervenir rapidement chez vous.</p>
            <p>Que ce soit pour une simple remise de courant, un remplacement de composants électriques défectueux, une mise en conformité électrique, un changement ou une réparation de tableau électrique, un remplacement ou une installation de prises électriques, ou encore pour des solutions électriques d'économie d'énergie,nous sommes en mesure de répondre à tous vos besoins. Nous sommes également spécialiser en dépannage de sécurité tel que contrôle d’accès.</p>
            <p>N'hésitez pas à nous contacter pour tout problème d'électricité ou de sécurité, et notre équipe se fera un plaisir de vous proposer une solution adaptée.</p>
              </div> </div> </div>
      </React.Fragment>
    );
  }
}

export default DepannageView;
