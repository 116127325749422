import React, { lazy, useState } from "react";
import { ReactComponent as IconEnvelopeFill } from "bootstrap-icons/icons/envelope-fill.svg";
import { ReactComponent as IconHouseFill } from "bootstrap-icons/icons/house-fill.svg";
import { ReactComponent as IconTelephoneFill } from "bootstrap-icons/icons/telephone-fill.svg";
import GoogleMapReact from 'google-map-react';
import { requestLocal } from "../../data/dataUtils";
import ContactUsForm from "./ContactUsForm";


/* const ContactUsForm = lazy(() => import("./ContactUsForm")); */

const ContactusView = ({ globalCart, setGlobalCart, globalUser, setGlobalUser, isSignOpen, setIsSignOpen }) => {

  let [error, setError] = useState(null);
  /*   function onSubmit(values) {
      alert(JSON.stringify(values));
      console.log(values);
    }; */

  async function onSubmit(values) {
    /* let dataToSend = {};
    dataToSend.user = {};
    dataToSend.user.nom = values.nom;
    dataToSend.user.prenom = values.prenom;
    dataToSend.user.telephone = values.telephone;
    dataToSend.user.email = values.email;
    dataToSend.user.commentaire = values.commentaire;
    dataToSend.user.formule = values.formule;
    dataToSend.formatedDate = day._d;
    dataToSend.couverts = nbCouverts;
    console.log(dataToSend); */
    //alert(JSON.stringify(dataToSend));



    let url = 'https://atpro.fr/mail.php';
    url = requestLocal(url, values, "POST");

    await fetch(url.url, url.options)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw response;
      })
      .then(data => {
        console.log('envoye');
      })
      .catch(error => {
        console.error("Error fetching", error);
        setError(error);
      })
      .finally(() => {
      }
      );


  };






  let [mapConfig, setmapConfig] = useState({
    center: {
      lat: 45.7110282,
      lng: 4.9225294
    },
    zoom: 17
  })


  //const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    new maps.Marker({
      icon: '../../assets/icons/map-marker.png',
      position: mapConfig.center,
      map,
      title: "Hello World!",
    });

    var styles = [{
      "featureType": "landscape",
      "elementType": "labels",
      "stylers": [{
        "visibility": "off"
      }]
    },
    {
      "featureType": "transit",
      "elementType": "labels",
      "stylers": [{
        "visibility": "off"
      }]
    },
    {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [{
        "visibility": "off"
      }]
    },
    {
      "featureType": "water",
      "elementType": "labels",
      "stylers": [{
        "visibility": "off"
      }]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [{
        "visibility": "off"
      }]
    },
    {
      "stylers": [{
        "hue": "#00aaff"
      },
      {
        "saturation": -100
      },
      {
        "gamma": 2.15
      },
      {
        "lightness": 12
      }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [{
        "visibility": "on"
      },
      {
        "lightness": 24
      }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [{
        "lightness": 57
      }]
    }
    ]

    map.setOptions({
      styles: styles
    });
  };

  return (
    <React.Fragment>

      <div className="container my-3">
        <div className="row g-3" >

          <div className="col-md-4  mb-3">
            <div className="_card">
              <div className="card-header">
                <IconHouseFill className="i-va" /> Adresse
              </div>
              <div className="card-body">
                {/*  <h6 className="card-title border-bottom border-dark pb-2">Head Office</h6> */}
                <address>
                  32 rue du Dauphiné
                  <br />
                  69800 SAINT PRIEST
                  <br />
                  <IconTelephoneFill className="i-va" />{" "}
                  <a href="tel:04 69 67 00 34">Tél.04 69 67 00 34</a><br />
                  <a href="https://goo.gl/maps/G6eiWZT4BUcgYD1f9"
                    target="_blank">Voir sur Google Map</a>
                </address>

                {/* <h6 className="card-title border-bottom border-dark pb-2">Development Office</h6>
                <address>
                  <strong>Twitter, Inc.</strong>
                  <br />
                  1355 Market St, Suite 900
                  <br />
                  San Francisco, CA 94103
                  <br />
                  <IconTelephoneFill className="i-va" />{" "}
                  <abbr title="Phone">P:</abbr> (123) 456-7890
                </address> */}
              </div>
            </div>
            {/* <div className="card">
            <div className="google-maps">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d313635.5491853188!2d-122.57606416467848!3d37.20933611930123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085815c67b3754d%3A0xb42714f3436732f2!2s1355%20Market%20St%20%23900%2C%20San%20Francisco%2C%20CA%2094103%2C%20USA!5e0!3m2!1sen!2sin!4v1599193189366!5m2!1sen!2sin"
                width={400}
                height={300}
                frameBorder={0}
                style={{ border: 0 }}
                allowFullScreen
                aria-hidden="false"
                tabIndex={0}
                title="Location"
              />
            </div>
          </div> */}
          </div>
          <div className="col-md-8 mb-3">
            <div className="card">
              <div className="_google-maps">
                <div style={{ height: '208px', width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAKyXRRSy-qzKLzhW6QwpJTOBoruLdHIrk' }}
                    defaultCenter={mapConfig.center}
                    defaultZoom={mapConfig.zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                  >
                    {/*               <AnyReactComponent
                lat={45.7661125}
                lng={4.8412646}
                text="My Marker"
              /> */}
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>


        </div>
        <div className="row g-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <IconEnvelopeFill className="i-va" /> Nous envoyer un message
              </div>
              <div className="card-body">
                <ContactUsForm onSubmit={onSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>



    </React.Fragment>
  );
}
export default ContactusView;
