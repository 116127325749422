import React, { Component, lazy } from "react";
import elec from '../../../img/elec.jpg';
import './inst.css';
import LogoCarousel from "../../../components/LogoCarousel";
const Widgets = lazy(() => import("../../../components/blog/Widgets"));
//const ContactUsRappelForm = lazy(() => import("../../components/ContactUsRappelForm"));

class InstallationView extends Component {
constructor(props) {
super();
this.state = {};
}
render() {
return (
<React.Fragment>
<div>
<img
         src={elec}
         className="card-img rounded-0"
         alt="..."
       />
      
<br></br><br></br>
 <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="display-6 border-bottom pb-3 mb-1">
              <strong>INSTALLATION ELECTRIQUE</strong> 
              </div>
              </div>
              <div className="sous-titre">Notre expertise en électricité:</div>
              <p>Nous offrons une variété de services pour répondre à tous vos besoins électriques. Que vous cherchiez à ajouter des prises ou des radiateurs ou rénover votre installation électrique existante, installer ou remplacer votre tableau électrique, nos experts qualifiés sont là pour vous aider.</p>
              <div className="sous-titre">Mise aux normes électriques:</div>
              <p>Nous nous engageons également à remettre votre installation électrique aux normes en vigueur pour assurer votre sécurité dans votre habitation comme dans vos locaux professionnels. Nous utilisons des matériaux et des équipements de qualité supérieure pour garantir une installation fiable et durable.</p>

<div className="title">AT Pro est spécialisée dans les installations électriques pour les particuliers et les entreprises.</div>
<p>Nous proposons des solutions sur-mesure pour répondre à tous vos besoins en électricité.</p>
<div className="title">Nous vous offrons des prestations variées, allant d’un simple dépannage jusqu’à la reprise totale de votre installation.</div>
<p>Nous sommes également là pour vous dépanner en cas de panne d'électricité, nous nous déplaçons rapidement pour rétablir le courant chez vous. N'hésitez pas à nous contacter pour obtenir un devis gratuit pour vos travaux.</p>
<LogoCarousel/></div></div></div>
</React.Fragment>
    );
  }
}
export default InstallationView;