import React from "react";
import "./alarme,controle,vs.css";
import ajax1 from '../../../img/ajax1.jpg';
import ajax2 from '../../../img/ajax2.jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
const Alarme = () => {
    return (
    <div className="page">
    <h1>Alarme</h1>
   <img src={ajax1} /> 
    <p>Nous offrons une gamme de systèmes d'alarme pour protéger contre les intrusions indésirables. Que vous cherchiez un système filaire ou sans fil, nous avons des options adaptées à tous les besoins.</p> <p>Ces systèmes peuvent être personnalisés en fonction de la taille et de la configuration de ce que vous désirez sécuriser et peuvent également être interroperable, votre maison devient intelligente et plus agréable à vivre au quotidien.</p><img src={ajax2} alt="Alarme" />  
    <button className="btn">
        <Link to={{ pathname: `/securite` }}>
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
    </button>
 </div>
  );
};

export default Alarme;