import React, { useEffect, useState, ReactDOM } from "react";
import { Card, Button } from 'react-bootstrap';
/*import { useParams, useHistory, useLocation } from "react-router-dom"; */
import "./Home.css";
import { images } from "../../../data/images";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from "react-router-dom";
import ta from '../../../img/T.A.jpg';


const HomeView = () => {
    const [index, setIndex] = useState(0);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        afterChange: setIndex,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    {

        let [panneauxClass, setPanneauxClass] = useState('bg-1')
        let [panneau1Class, setPanneau1Class] = useState('panneauHover')
        let [panneau2Class, setPanneau2Class] = useState('panneauFade')
        let [panneau3Class, setPanneau3Class] = useState('panneauFade')
        let [panneau4Class, setPanneau4Class] = useState('panneauFade')
        let [isLargeScreen, setIsLargeScreen] = useState(window.matchMedia("(min-width: 768px)").matches)

        useEffect(() => {
            const handler = e => setIsLargeScreen(e.matches);
            window.matchMedia("(min-width: 768px)").addEventListener('change', handler);
            // eslint-disable-next-line
        }, []);

        images.homeSlider.map(function (item, i) {
            item.src = '../../assets/slider/' + item.src;
        })

        const IMAGES =
            [{
                src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
                thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
                thumbnailWidth: 320,
                thumbnailHeight: 174,
                isSelected: false,
                caption: "After Rain (Jeshu John - designerspics.com)"
            },
            {
                src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
                thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
                thumbnailWidth: 320,
                thumbnailHeight: 212,
                tags: [{ value: "Ocean", title: "Ocean" }, { value: "People", title: "People" }],
                caption: "Boats (Jeshu John - designerspics.com)"
            },

            {
                src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
                thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
                thumbnailWidth: 320,
                thumbnailHeight: 212
            }]

        function onSelectImage(index, image) {
            var images = this.state.images.slice();
            var img = images[index];
            if (img.hasOwnProperty("isSelected"))
                img.isSelected = !img.isSelected;
            else
                img.isSelected = true;

            this.setState({
                images: images
            });
        }

        /*     const responsive = {
                superLargeDesktop: {
                    // the naming can be any, depends on you.
                    breakpoint: { max: 4000, min: 3000 },
                    items: 5
                },
                desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3
                },
                tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 2
                },
                mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1
                }
            }; */

        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 1
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 1
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1
            }
        };

        /*     $('#panneau1').hover(function () {
                console.log("pass");
                $(this).parents().css("background-color", "red");
                $(this).css("width", "30%");
            }
            )
            $('#panneau2').hover(function () {
                $(this).parents().css("background-color", "blue");
                $(this).css("width", "30%");
            }
            ) */

        const changeBackground = (e, id) => {
            console.log(e);
            console.log(id);

            /* let test = ReactDOM.findDOMNode(this).parentNode.getAttribute("id")
            console.log(test); */
            const parentDiv = e.target.parentNode;
            //parentDiv.classList.toggle('someClass');
            //parentDiv.classList.add('someClass');
            if (id === 1) {
                setPanneauxClass('bg-1')
                setPanneau1Class('panneauHover')
                setPanneau2Class('panneauFade')
                setPanneau3Class('panneauFade')
                setPanneau4Class('panneauFade')
            }
            if (id === 2) {
                setPanneauxClass('bg-2')
                setPanneau1Class('panneauFade')
                setPanneau2Class('panneauHover')
                setPanneau3Class('panneauFade')
                setPanneau4Class('panneauFade')
            }
            if (id === 3) {
                setPanneauxClass('bg-3')
                setPanneau1Class('panneauFade')
                setPanneau2Class('panneauFade')
                setPanneau3Class('panneauHover')
                setPanneau4Class('panneauFade')
            }
            if (id === 4) {
                setPanneauxClass('bg-4')
                setPanneau1Class('panneauFade')
                setPanneau2Class('panneauFade')
                setPanneau3Class('panneauFade')
                setPanneau4Class('panneauHover')
            }
        }

        const revertBackground = (e, id) => {
            console.log(e);
            console.log(id);

            /* let test = ReactDOM.findDOMNode(this).parentNode.getAttribute("id")
            console.log(test); */
            const parentDiv = e.target.parentNode;
            //parentDiv.classList.toggle('someClass');
            //parentDiv.classList.add('someClass');
            /*         if (id === 1) {
                        setPanneau1Class('')
                        setPanneau2Class('panneauFade')
                        setPanneau3Class('panneauFade')
                        setPanneau4Class('panneauFade')
                    }
                    if (id === 2) {
                        setPanneau1Class('panneauFade')
                        setPanneau2Class('')
                        setPanneau3Class('panneauFade')
                        setPanneau4Class('panneauFade')
                    }
                    if (id === 3) {
                        setPanneau1Class('panneauFade')
                        setPanneau2Class('panneauFade')
                        setPanneau3Class('')
                        setPanneau4Class('panneauFade')
                    }
                    if (id === 4) {
                        setPanneau1Class('panneauFade')
                        setPanneau2Class('panneauFade')
                        setPanneau3Class('panneauFade')
                        setPanneau4Class('')
                    } */
        }

        return (
            <React.Fragment>
                <div className="container-fluid mb-3">
                    {isLargeScreen ?
                        <div className="row ">
                            <div className="col-md-12">
                                {/* <div className="d-flex panneaux "> */}
                                <div className={'d-flex panneaux   ' + panneauxClass}>
                                    <div id="panneau1" className={'p-2 flex-grow-1 panneau ' + panneau1Class} onMouseOver={(e) => (changeBackground(e, 1))} onMouseOut={(e) => (revertBackground(e, 1))}>
                                        <div className="row  py-7">
                                            <div className="col-md-12 text-center align-self-center _ps-5  mb-4">{/* d-flex align-items-center */}
                                                <div className="row">
                                                    <Link to={`/securite`}>
                                                        <div className="col-md-12">
                                                            <h1 className="cOYrPE-Dark  mb-3">Sécurité</h1></div>
                                                    </Link>
                                                </div>
                                                <div className="_d-flex btnGroup">
                                                    <Link to={`/securite`}>
                                                        <Button className="btn btn-primary">En savoir plus</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="panneau2" className={'p-2 flex-grow-1 panneau ' + panneau2Class} onMouseOver={(e) => (changeBackground(e, 2))} onMouseOut={(e) => (revertBackground(e, 1))}>
                                        <div className="row  py-7">
                                            <div className="col-md-12 text-center align-self-center _ps-5  mb-4">{/* d-flex align-items-center */}
                                                <div className="row">
                                                    <Link to={{ pathname: `/installation` }} className="product-link">
                                                        <div className="col-md-12">
                                                            <h1 className="cOYrPE-Dark  mb-3">Installation électrique</h1>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="_d-flex btnGroup">
                                                    <Link to={{ pathname: `/installation` }} className="product-link">
                                                        <Button className="btn btn-primary">En savoir plus</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div id="panneau3" className={'p-2 flex-grow-1 panneau ' + panneau3Class} onMouseOver={(e) => (changeBackground(e, 3))} onMouseOut={(e) => (revertBackground(e, 1))}>

                                        <div className="row  py-7">
                                            <div className="col-md-12 text-center align-self-center _ps-5  mb-4">{/* d-flex align-items-center */}
                                                <div className="row">
                                                    <Link to={{ pathname: `/climatisation` }} className="product-link">
                                                        <div className="col-md-12">
                                                            <h1 className="cOYrPE-Dark  mb-3">Chauffage et Climatisation</h1></div>
                                                    </Link>
                                                </div>
                                                <div className="_d-flex btnGroup">
                                                    <Link to={{ pathname: `/climatisation` }} className="product-link">
                                                        <Button className="btn btn-primary">En savoir plus</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="panneau4" className={'p-2 flex-grow-1 panneau ' + panneau4Class} onMouseOver={(e) => (changeBackground(e, 4))} onMouseOut={(e) => (revertBackground(e, 1))}>
                                        <div className="row  py-7">
                                            <div className="col-md-12 text-center align-self-center _ps-5  mb-4">{/* d-flex align-items-center */}
                                                <div className="row">
                                                    <Link to={{ pathname: `/depannage` }} className="product-link">
                                                        <div className="col-md-12">
                                                            <h1 className="cOYrPE-Dark  mb-3">Dépannage</h1></div>
                                                    </Link>
                                                </div>
                                                <div className="_d-flex btnGroup">
                                                    <Link to={{ pathname: `/depannage` }} className="product-link">
                                                        <Button className="btn btn-primary">En savoir plus</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        :
                        <div className="row ">
                            <div className="col-md-12">
                                <Link to={`/securite`}>
                                    <div className="banner-home-1 d-flex justify-content-center align-items-center">
                                        <h1 className="cOYrPE-Dark  mb-3">Sécurité</h1>
                                    </div>
                                </Link>
                                <Link to={{ pathname: `/installation` }} className="product-link">
                                    <div className="banner-home-2 d-flex justify-content-center align-items-center">
                                        <h1 className="cOYrPE-Dark  mb-3">Installation électrique</h1>
                                    </div>
                                </Link>
                                <Link to={{ pathname: `/climatisation` }} className="product-link">
                                    <div className="banner-home-3 d-flex justify-content-center align-items-center">
                                        <h1 className="cOYrPE-Dark  mb-3">Chauffage et Climatisation</h1>
                                    </div>
                                </Link>
                                <Link to={{ pathname: `/depannage` }} className="product-link">
                                    <div className="banner-home-4 d-flex justify-content-center align-items-center">
                                        <h1 className="cOYrPE-Dark  mb-3">Dépannage</h1>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    }

                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="h1">
                                <strong> Bienvenue sur notre site:</strong>   <br></br>

                            </div>
                            <br></br>




                            <p className="p1">ATPro est une entreprise spécialisée dans les installations électriques et de sécurité pour les particuliers et les entreprises. Nous proposons des solutions sur-mesure pour répondre à tous vos besoins en électricité, chauffage, domotique, alarme, vidéosurveillance ou même controle d'accès. Nous vous offrons des prestations variées, allant du simple dépannage à l'installation complète
                                . Nous disposons d'une équipe qualifiée et expérimentée pour vous garantir un service professionnel et efficace pour tous vos projets. N'hésitez pas à nous contacter pour obtenir un devis gratuit pour vos travaux.
                                &nbsp;</p>
                            <img src={ta} className="center-image" />

                        </div>
                    </div>
                </div>

            </React.Fragment >
        );
    }
}
export default HomeView;

