import { Link } from 'react-router-dom';
import logoat from '../img/logoat.jpg';
import React from "react";

const Navbar = () => {
  return (
    <nav>
      <Link to="/">
  <img src={logoat} className="logo" style={{ width: 'auto' }} />
</Link>
      <Link to="/securite">Sécurité</Link>
      <Link to="/installation">Installation électrique</Link>
       <Link to="/climatisation">Chauffage et climatisation</Link>
      <Link to="/depannage">Dépannage</Link>
      <Link to="/contact-us">Contact</Link>
    </nav>
  )
}

export default Navbar







