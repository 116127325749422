import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { Link } from 'react-router-dom';
import "./App.css";
import "./App.min.css";
import Navbar from './components/Navbar';
import Footer from "./components/Footer";
import HomeView from "./views/pages/Home/Home";
import SecuriteView from "./views/pages/pages/securite"
import InstallationView from "./views/pages/pages/installation"
import ClimatisationView from "./views/pages/pages/climatisation"
import DepannageView from "./views/pages/pages/depannage"
import ContactUsView from "./views/Contact/Contactus";
//const ContactUsView = lazy(() => import("./views/Contact/ContactUs"));
import Videosurveillance from "./views/pages/pages/Videosurveillance";
import Alarme from "./views/pages/pages/Alarme";
import Controledacces from "./views/pages/pages/Controledacces";
import Button from './components/Button';
function App(props) {

  return (
    <Router>
      <Navbar />
      <Switch>
        <Suspense>
          <Route exact path="/" component={HomeView} />
          <Route exact path="/securite" component={SecuriteView} />
          <Route exact path="/installation" component={InstallationView} />
          <Route exact path="/climatisation" component={ClimatisationView} />
          <Route exact path="/depannage" component={DepannageView} />
          <Route exact path="/contact-us" component={ContactUsView} />
          <Route exact path="/alarme" component={Alarme} />
          <Route exact path="/videosurveillance" component={Videosurveillance} />
          <Route exact path="/controledacces" component={Controledacces} />
        </Suspense>
      </Switch>
      <Button />
      <Footer />
    </Router>
  );
}

export default App;
