import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconTelephone } from "bootstrap-icons/icons/telephone.svg";
import { ReactComponent as IconEnvelope } from "bootstrap-icons/icons/envelope.svg";
import { ReactComponent as IconBriefcase } from "bootstrap-icons/icons/briefcase.svg";
import { ReactComponent as IconBadgeAd } from "bootstrap-icons/icons/badge-ad.svg";
import { ReactComponent as IconGift } from "bootstrap-icons/icons/gift.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
  faApple,
  faWindows,
  faAndroid,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <React.Fragment>
      <footer>
        <div className="container-fluid bg-dark text-white">
          <div className="row ">
            <div className="col-md-3 offset-md-3 py-3">
              <div className="h6">NOUS CONTACTER</div>
              <hr />
              <p>Adresse : <br />32 rue du Dauphiné 69800 SAINT PRIEST</p>
              <p className="email">
                <strong> Email :</strong><br />contact@atpro.fr
              </p>
              <p className="phon">
                <strong>Téléphone :</strong><br />04 69 67 00 34
              </p>
            </div>
            <div className="col-md-3 py-3">
              <div className="h6">Horaires d'ouverture</div>
              <hr />
              <p>Du Lundi au Vendredi</p>
              <div className="open-list">
                <ul>
                  <li>Lundi - Vendredi <span>8h - 19h</span></li>
                </ul>
              </div>
            </div>

            <div className="col-md-3 py-3 text-center">
              <div className="h6">Suivez-nous</div>
              <hr />
              <div className="social-icons">
                <a href="https://www.facebook.com/ATProLyon/">
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    size="2x"
                    className="mx-3"
                  />
                </a>
                <a href="https://www.instagram.com/_atpro_/?hl=fr">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    className="mx-3"
                  />
                </a>
                <a href="#">
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    size="2x"
                    className="mx-3"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*         <div className="container-fluid bg-secondary text-white text-center">
          <div className="row">
            <div className="col-md-2 py-2">
              <Link to="/" className="text-white text-decoration-none">
                <IconBriefcase className="text-warning" /> Partner with us
              </Link>
            </div>
            <div className="col-md-2 py-2">
              <Link to="/" className="text-white text-decoration-none">
                <IconBadgeAd className="text-info" /> Advertise
              </Link>
            </div>
            <div className="col-md-2 py-2">
              <Link to="/" className="text-white text-decoration-none">
                <IconGift className="text-dark" /> Gift
              </Link>
            </div>
            <div className="col-md-3 py-2">
              © 2009-{new Date().getFullYear()} React-E-Commerce.com
            </div>
            <div className="col-md-3 py-2 bg-white">
              <img
                src="../../images/payment/american_express.webp"
                width="32"
                alt="American Express"
                className="mr-2"
              />
              <img
                src="../../images/payment/maestro.webp"
                width="32"
                alt="Maestro"
                className="mr-2"
              />
              <img
                src="../../images/payment/netbanking.webp"
                width="32"
                alt="Net Banking"
                className="mr-2"
              />
              <img
                src="../../images/payment/paypal.webp"
                width="32"
                alt="Paypal"
                className="mr-2"
              />
              <img
                src="../../images/payment/rupay.webp"
                width="32"
                alt="Rupay"
                className="mr-2"
              />
              <img
                src="../../images/payment/upi.webp"
                width="32"
                alt="UPI"
                className="mr-2"
              />
              <img
                src="../../images/payment/visa.webp"
                width="32"
                alt="Visa"
                className="mr-2"
              />
            </div>
          </div>
        </div> */}
      </footer>
    </React.Fragment >
  );
};
export default Footer;
