import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import {
  renderFormGroupField,
  renderFormTextArea,
  renderFormCheckbox,
  renderFormSelect,
} from "../../helpers/renderForm";
import {
  required,
  maxLength50,
  name,
  maxLengthMobileNo,
  minLengthMobileNo,
  digit,
  email,
  maxLength1000,
} from "../../helpers/validation";
import { ReactComponent as IconPersonFill } from "bootstrap-icons/icons/person-fill.svg";
import { ReactComponent as IconBuilding } from "bootstrap-icons/icons/building.svg";
import { ReactComponent as IconPhoneFill } from "bootstrap-icons/icons/phone-fill.svg";
import { ReactComponent as IconEnvelopeFill } from "bootstrap-icons/icons/envelope-fill.svg";

const ContactUsForm = (props) => {
  const { handleSubmit, submitting, onSubmit, submitFailed } = props;
  // eslint-disable-next-line
  let [selectOptions, setSelectOptions] = useState([{ 'value': 'test', 'label': 'test' }, { 'value': 'test1', 'label': 'test1' }])
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`needs-validation ${submitFailed ? "was-validated" : ""}`}
      noValidate
    >
      <div className="row">
        <div className="col-md-6">
          <Field
            name="prenom"
            type="text"
            label="Prénom"
            component={renderFormGroupField}
            placeholder="Votre Prénom"
            icon={IconPersonFill}
            required={true}
            validate={[required, maxLength50]}
            pattern="[a-zA-Z\s]*"
            maxLength="50"
            className="mb-3"
          />
        </div>
        <div className="col-md-6">
          <Field
            name="name"
            type="text"
            label="Nom"
            component={renderFormGroupField}
            placeholder="Votre Nom"
            icon={IconPersonFill}
            required={true}
            validate={[required, maxLength50]}
            pattern="[a-zA-Z\s]*"
            maxLength="50"
            className="mb-3"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <Field
            name="telephone"
            type="number"
            label="Numéro de téléphone"
            component={renderFormGroupField}
            placeholder="Numéro de téléphone"
            icon={IconPhoneFill}
            validate={[required, maxLengthMobileNo, minLengthMobileNo, digit]}
            required={true}
            max="999999999999999"
            min="9999"
            className="mb-3"
          />
        </div>
        <div className="col-md-6">
          <Field
            name="email"
            type="email"
            label="Adresse email"
            component={renderFormGroupField}
            placeholder="Votre adresse email"
            icon={IconEnvelopeFill}
            validate={[required, maxLength50, email]}
            maxLength="50"
            required={true}
            className="mb-3"
          />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-md-12">
          <Field
            name="message"
            label="Message"
            maxLength="1000"
            component={renderFormTextArea}
            validate={maxLength1000}
            placeholder="En quoi pouvons-nous vous aider ?"
          />
        </div>
      </div>

      <button
        type="submit"
        className="btn btn-primary btn-block mt-3"
        disabled={submitting}
      >
        Envoyer
      </button>
    </form>
  );
};

export default compose(
  reduxForm({
    form: "demo1",
  })
)(ContactUsForm);
