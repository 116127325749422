import React from 'react';
/* import { FaEnvelope } from 'react-icons/fa'; */
import { Link } from 'react-router-dom';
import './but.css';
const ContactButton = () => {
    return (
        <div >
            <button id='contact-button'>
                <Link to={{ pathname: `/contact-us` }}>
                    {/* <FaEnvelope className="email-icon" />  */}Contact
                </Link>
            </button>
        </div>
    );
}

export default ContactButton;