import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './carousel.css';
import legrand from '../img/legrand.png';
const LogoCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const logos = [
    {
      src:legrand,
      href:"https://www.electriciencertifie.fr/rechercher-un-electricien/pro-st-priest"
    }
   
  ];

  const handlePrev = () => {
    setCurrentIndex(currentIndex === 0 ? logos.length - 1 : currentIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex(currentIndex === logos.length - 1 ? 0 : currentIndex + 1);
  };

  return (
    
    <div className="logo-carousel">
     <p class="title">Nos Partenaires Certifiés :</p>
      <CSSTransition
        in={true}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <img
          src={logos[currentIndex].src}
          alt={logos[currentIndex].alt}
          className="logo"
        />
      </CSSTransition>
     
    </div>
  );
};

export default LogoCarousel;
