import React, { Component, lazy } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconCalendarEvent } from "bootstrap-icons/icons/calendar-event.svg";
import { ReactComponent as IconHandThumbsUp } from "bootstrap-icons/icons/hand-thumbs-up.svg";
import { ReactComponent as IconChatText } from "bootstrap-icons/icons/chat-text.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
const Widgets = lazy(() => import("../../../components/blog/Widgets"));
//const ContactUsRappelForm = lazy(() => import("../../components/ContactUsRappelForm"));

class ClimatisationView extends Component {
  constructor(props) {
    super();
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="card bg-dark text-white rounded-0 mb-3">
          <img
            src="../../assets/Images/Slider/img-long1.jpg"
            className="card-img rounded-0"
            alt="..."
          />
          <div className="card-img-overlay text-center">
            <h1 className="card-title position-absolute bottom-0 mb-3">
              {/* Caméra intérieur ou extérieur, Lutte contre la démarque inconnue, dissuasion , surveillance interne , accès à distance, enregistrement , reconnaissance faciale, lecture de plaques … Grace aux dernières technologies les possibilités sont infinis pour la vidéosurveillance. */}
            </h1>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="display-6 border-bottom pb-3 mb-1">
               <strong>CLIMATISATION ET CHAUFFAGE</strong>
              </div>
              <div className="sous-titre">Notre expertise en climatisation et chauffage:</div>
          <p>
            Chez AT Pro, nous sommes spécialisés dans l'installation, la
            réparation et l'entretien de systèmes de chauffage et de
            climatisation. Nous sommes conscient que l’installation la réparation et l’entretient de votre système de chauffage et de climatisation est primordiale.Le choix entre les différent dispositifs est parfois difficile, c'est pourquoi
            nous sommes là pour vous aider à chaque étape du processus. Nous répondrons à vos besoins et à tous les budgets.
          </p>
          <p>
          Notre équipe de techniciens qualifiés est spécialement formée pour
          installer, réparer et entretenir tous les types de systèmes de
          chauffage et de climatisation, qu'il s'agisse d'une maison
          individuelle ou d'un bâtiment commercial.
        </p>
            <div className="sous-titre">
            Contrats d'entretien personnalisés:
            </div>
        <p>
      En plus de notre engagement à fournir des services de qualité, nous
      offrons également des contrats d'entretien personnalisés pour assurer
      la durabilité de votre système de chauffage ou de climatisation.
      Entretenir régulièrement votre système prolongera non seulement  sa
      durée de vie, mais vous aidera également à économiser de l'énergie à
      long terme.
      </p>
      <div className="title">
      AT Pro, le choix idéal pour tous vos besoins en matière de chauffage
      et de climatisation.
      </div>
      <p>
      N'hésitez pas à nous contacter pour discuter de vos besoins et
      obtenir un devis gratuit.
      </p>


            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ClimatisationView;
