  import React, { Component, lazy, useState } from "react";
  import './securite.css';
  import vid1 from '../../../img/securité.jpg';
  import Alarme from './Alarme';
  import Videosurveillance from './Videosurveillance';
  import Controledacces from './Controledacces';
  const Widgets = lazy(() => import("../../../components/blog/Widgets"));



  class SecuriteView extends Component {
    // le code restant ici
  
  handleClick = (page) => {
    switch (page) {
      case "Alarme":
        this.props.history.push(`/alarme`);
        break;
      case "Vidéosurveillance":
        this.props.history.push(`/videosurveillance`);
        break;
      case "Contrôle d'accès":
        this.props.history.push(`/controledacces`);
        break;
      default:
        break;
    }
  }
    render() {
      return (
        <React.Fragment>
          
          <div className="security">
  <img src={vid1} className="card-img rounded-0" alt="..." />
  <br />
  <h1>
    <strong>Sécurité</strong>
  </h1>
  <div>
    <p className="p1">
    Pour la sécurité de votre propriété, nous proposons des systèmes d'alarme performants pour vous protéger contre les intrusions indésirables. Nos caméras de vidéosurveillance vous permettent de surveiller et d'identifier une situation en temps réel ou en relecture ou que vous soyez. Nous utilisons également des systèmes de contrôle d'accès pour gérer les accès et assurer la sécurité de vos biens et de vos proches. Nous sommes déterminés à vous offrir la meilleure sécurité possible pour votre maison ou votre entreprise.
    </p>
  </div>
  <br />
  <br />
  <button id="btn6" className="btn"  onClick={() => this.handleClick("Alarme")}><strong>Alarme</strong></button>
<button id="btn69" className="btn"   onClick={() => this.handleClick("Vidéosurveillance")}><strong>Vidéosurveillance</strong></button>
<button id="btn696" className="btn"  onClick={() => this.handleClick("Contrôle d'accès")}><strong>Contrôle d'accès</strong></button>

  <br></br>
  <h2 tabIndex={0} onClick={() => this.handleClick("Alarme")}>
    <i className="fas fa-bell"></i> Alarme
  </h2>
  <h3 tabIndex={0} onClick={() => this.handleClick("Vidéosurveillance")}>
    <i className="fas fa-video"></i> Vidéosurveillance
  </h3>
  <h4 tabIndex={0} onClick={() => this.handleClick("Contrôle d'accès")}>
    <i className="fas fa-key"></i> Contrôle d'accès
  </h4>
  <br />
  <br />
</div>

        </React.Fragment>
      );
    }
  }

  export default SecuriteView;
