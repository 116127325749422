import React from 'react';
import { Link } from 'react-router-dom';
import ca from '../../../img/ca.jpg';
import ca3 from '../../../img/ca3.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import "./alarme,controle,vs.css";
const Controledacces = () => {
   
    return (
        <div className="page">
        <h1>Contrôle d'accès</h1>
        <img className="foto1" src={ca}alt="Alarme" />
        <p>Nous offrons une variété de systèmes pour gérer l'accès à votre entreprise. Que vous cherchiez des serrures électroniques, des lecteurs d'empreintes digitales ou des systèmes à carte, nous avons des options adaptées à tous les besoins.</p> <p>Ces systèmes peuvent également être intégrés à d'autres dispositifs de sécurité pour une sûreté renforcée.</p> <img className="foto2" src={ca3}alt="Alarme" />
  
  <button className="btn">
  <Link to={{ pathname: `/securite` }}>
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
    </button>
     </div>
    )
}

export default Controledacces;
